<script>
import { mapGetters } from 'vuex';
import utilsMixin from '@shared/mixins/utils';
import APIAffiliate from '@app/services/API/Affiliate';
import AffiliatePayoutDecisionForm from '@app/components/AffiliatePayoutDecisionForm.vue';

export default {
  mixins: [utilsMixin],
  data: () => ({
    isFetching: false,
    affiliates: {
      data: [],
    },
  }),
  computed: {
    ...mapGetters('auth', {
      authStore: 'store',
    }),
  },
  methods: {
    fetchData() {
      this.isFetching = true;
      APIAffiliate.getAffiliates(
        { storeUUID: this.authStore.uuid },
        { has_pending_payout: 1 },
      )
        .then((data) => (this.affiliates = data))
        .finally(() => (this.isFetching = false));
    },
    showInstructions(affiliate) {
      this.$buefy.dialog.alert({
        message: affiliate.payout_info,
      });
    },
    markAsPaid(affiliate) {
      const modal = this.$buefy.modal.open({
        parent: this,
        hasModalCard: true,
        canCancel: false,
        component: AffiliatePayoutDecisionForm,
        props: { affiliate },
        events: {
          cancel: () => {
            modal.close();
          },
          done: (affiliate) => {
            modal.close();
            this.affiliates.data = this.affiliates
              .data
              .filter((v) => v.uuid !== affiliate.uuid);
          },
        },
      });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<template>
  <section v-if="!isFetching && affiliates.data.length > 0">
    <b-skeleton v-if="isFetching" height="400" />
    <template v-else>
      <h2 class="title is-4">
        Commissions en attente de règlement
      </h2>
      <b-table class="box" :data="affiliates.data">
        <b-table-column
          field="customer.firstname"
          label="Affilié"
          v-slot="{ row }"
        >
          <div>
            <p>
              <router-link
                class="has-text-black"
                :to="{name: 'affiliate', params: {uuid: row.uuid}}"
              >
                {{ row.customer.firstname }} {{ row.customer.lastname }}
              </router-link>
            </p>
            <p class="has-text-weight-bold">
              <router-link
                class="has-text-black"
                :to="{name: 'affiliate', params: {uuid: row.uuid}}"
              >
                {{ row.customer.email }}
              </router-link>
            </p>
            <p class="mt-2">
              Demande effectuée
              <b-tooltip>
                <template #content>
                  {{ row.payout_request_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
                </template>
                {{ row.payout_request_at | momentFromUTC | moment('from') }}
              </b-tooltip>
            </p>
            <p
              v-if="row.payout_invoice_url"
              class="mt-2 break-words"
            >
              <strong>Lien de la facture</strong> :
              <a
                class="has-text-black tdecoration-underline"
                :href="row.payout_invoice_url"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ row.payout_invoice_url }}
              </a>
            </p>
          </div>
        </b-table-column>
        <b-table-column
          field="payout_request_amount"
          label="Montant"
          numeric
          v-slot="{ row }"
        >
          {{ row.payout_request_amount | formatPrice }}
        </b-table-column>
        <b-table-column
          label="Actions"
          numeric
          v-slot="{ row }"
        >
          <div>
            <p>
              <b-button
                type="is-text"
                class="tdecoration-underline"
                @click="showInstructions(row)"
              >
                Voir le commentaire
              </b-button>
            </p>
            <p class="mt-1">
              <b-button @click="markAsPaid(row)">
                Accepter / Refuser
              </b-button>
            </p>
          </div>
        </b-table-column>
      </b-table>
    </template>
  </section>
</template>
