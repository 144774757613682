<script>
import utilsMixin from '@shared/mixins/utils';
import APIAffiliate from '@app/services/API/Affiliate';
import { mapGetters } from 'vuex';

export default {
  mixins: [utilsMixin],
  props: {
    affiliate: {
      type: Object,
      required: true,
    },
  },
  i18n: {
    messages: {
      fr: {
        ACCEPTED: 'accepter',
        REJECTED: 'refuser',
      },
    },
  },
  static: {
    form: {
      decision: {
        type: 'select',
        column: 'is-12',
        label: 'Décision',
        options: {
          ACCEPTED: 'Confirmer le règlement',
          REJECTED: 'Rejeter la demande de règlement',
        },
        inputAttrs: {
          required: true,
        },
        notificationClass: 'content mt-6',
        notification: `
          <p>
            <strong>Teachizy ne gère pas le règlement des commissions.</strong>
          </p>
          <p>
            En confirmant le règlement de cette commission,
            vous indiquez à l’affilié l'avoir déjà réglé par
            le moyen de votre choix (virement bancaire, Paypal…).
            <strong>Il faut donc procéder au règlement avant de confirmer le paiement de la commission.</strong>
          </p>
          <p>
            En rejetant la demande, vous indiquez à votre affilié
            que vous ne procèderez pas à son règlement : il faut lui expliquer votre décision
            pour que votre affilié puisse refaire sa demande ultérieurement, avec les bons éléments.
          </p>
        `,
      },
      comment: {
        type: 'textarea',
        column: 'is-12',
        label: 'Détail de la décision',
        inputAttrs: {
          required: true,
          hasCounter: false,
          maxlength: 512,
        },
      },
    },
  },
  data: () => ({
    isLoading: false,
    newDecision: {
      decision: 'ACCEPTED',
      comment: '',
    },
  }),
  computed: {
    ...mapGetters('auth', {
      authStore: 'store',
    }),
  },
  methods: {
    handle() {
      const newDecision = { ...this.newDecision };

      this.showAsyncConfirm({
        message: `
          Êtes-vous sûr.e de vouloir
          <strong>${this.$t(newDecision.decision)}</strong>
          cette demande de règlement ?
        `,
      }, ({ done }) => (
        APIAffiliate.managePayout(
          this.authStore.uuid,
          this.affiliate.uuid,
          newDecision,
        )
          .then(() => {
            done();
            this.$emit('done', this.affiliate);
          })
      ));
    },
  },
};
</script>

<template>
  <AppCardModal
    tag="form"
    @submit.prevent="dataIsValid(isLoading) && handle()"
  >
    <template #title>
      Traiter la demande de règlement
      ({{ affiliate.payout_request_amount | formatPrice }})
    </template>
    <template #body>
      <div class="columns is-multiline">
        <div
          v-for="(field, k) in $options.static.form"
          :key="k"
          class="column mb-0"
          :class="field.column"
        >
          <GeneratorField
            :fieldName="k"
            :fieldDefinition="field"
            :formData.sync="newDecision"
          />
        </div>
      </div>
    </template>
    <template #foot>
      <b-button type="is-text" @click="$emit('cancel')">
        Annuler
      </b-button>
      <b-button
        type="is-primary is-custom"
        native-type="submit"
        :loading="isLoading"
      >
        Envoyer
      </b-button>
    </template>
  </AppCardModal>
</template>
