<script>
import { mapGetters } from 'vuex';
import StatisticCard from '@shared/components/StatisticCard.vue';
import APIAffiliate from '@app/services/API/Affiliate';
import utilsMixin from '@shared/mixins/utils';

export default {
  mixins: [utilsMixin],
  components: { StatisticCard },
  data: () => ({
    isFetching: false,
    stats: null,
  }),
  computed: {
    ...mapGetters('auth', {
      authStore: 'store',
    }),
  },
  created() {
    this.isFetching = true;
    APIAffiliate.getAffiliatesStats(this.authStore.uuid)
      .then(({ data }) => (this.stats = data))
      .finally(() => (this.isFetching = false));
  },
};
</script>

<template>
  <section>
    <h2 class="title is-4">
      Vue d'ensemble
    </h2>
    <b-skeleton v-if="isFetching" height="200" />
    <template v-else>
      <div class="columns is-multiline">
        <div class="column is-4">
          <StatisticCard>
            <template #title>
              Visites
            </template>
            <template #tooltipContent>
              Nombre de visites depuis les liens d'affiliation
            </template>
            {{ stats.total_visits }}
          </StatisticCard>
        </div>
        <div class="column is-4">
          <StatisticCard>
            <template #title>
              Ventes
            </template>
            <template #tooltipContent>
              Nombre de ventes depuis les liens d'affiliation
            </template>
            {{ stats.total_sales }}
          </StatisticCard>
        </div>
        <div class="column is-4">
          <StatisticCard>
            <template #title>
              Taux de conversion
            </template>
            <template #tooltipContent>
              Pourcentage des visites ayant généré des ventes (depuis les liens d'affiliation)
            </template>
            {{ [stats.total_sales, stats.total_visits] | toPercentage }}%
          </StatisticCard>
        </div>
        <div class="column is-4">
          <StatisticCard>
            <template #title>
              Revenu
            </template>
            <template #tooltipContent>
              Revenus issus de l'affiliation
            </template>
            {{ stats.total_revenue | formatPrice }}
          </StatisticCard>
        </div>
        <div class="column is-4">
          <StatisticCard>
            <template #title>
              Commissions générées
            </template>
            <template #tooltipContent>
              Total des commissions
            </template>
            {{ stats.total_amount | formatPrice }}
          </StatisticCard>
        </div>
        <div class="column is-4">
          <StatisticCard>
            <template #title>
              Commissions payées
            </template>
            <template #tooltipContent>
              Commissions déjà réglées aux affiliés
            </template>
            {{ stats.total_paid | formatPrice }}
          </StatisticCard>
        </div>
      </div>
      <div
        v-if="stats.last_commission_at"
        class="box is-info content"
      >
        <p>
          <b-icon icon="coins" class="has-text-black mr-3" />
          <strong>Dernière vente via l'affiliation</strong> :
          {{ stats.last_commission_at | momentFromUTC | moment('[le] DD/MM/YYYY [à] HH:mm') }}
        </p>
      </div>
    </template>
  </section>
</template>
